import React, { useEffect, useState } from "react";
import "./manageAcc.css";
import back from "../../../assets/ArrowLeft.svg";
import brand1 from "../../../assets/images/logo.png";
import uploadIcon from "../../../assets/uploadIcon.svg";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { selectStyles, textFieldStyles } from "../../../styles/inputStyles";
import useFetch from "../../../utils/hooks/useFetch";
import { sectorEndpoints } from "../../../api/endpoints/sector.endpoints";
import { industryEndpoints } from "../../../api/endpoints/industry.endpoints";
import { organizationEndpoints } from "../../../api/apiEndpoints";
import { revenueEndpoints } from "../../../api/endpoints/revenue.endpoints";
import { countryEndpoints } from "../../../api/endpoints/country.endpoints";
import { employeeEndpoints } from "../../../api/endpoints/employee.endpoints";
import constant, { REGEX_PATTERNS } from "../../../constant";
import ImageModal from "../../common/ImageModal";
import defaultOrg from "../../../assets/defaultOrg.svg";
import defaultUser from "../../../assets/images/login/defaultUser.png";
import { useNavigate } from "react-router-dom";
import ImageCropper from "../../common/ImageCropper";
import GradientButton from "../../common/GradientButton";
import { industryApis } from "../../../api/industry.apis";
import useOrganizationContext from "../../../context/OrgContext";
import usePutApi from "../../../utils/hooks/usePutApi";
import { userEndpoints } from "../../../api/endpoints/user.endpoints";
import LogoutDialog from "../../Dashboard/LogoutDialog";
import { useAuth } from "../../context/AuthContext";

const ManageAccount = () => {
  const { data: sectorList } = useFetch(sectorEndpoints.getAll);
  // const { data: industryList } = useFetch(industryEndpoints.getIndustriesById());
  // const { data: orgData } = useFetch(organizationEndpoints.getOrganization);
  const { data: revenueData } = useFetch(revenueEndpoints.getAll);
  const { data: countryData } = useFetch(countryEndpoints.getAll);
  const { data: employeeCount } = useFetch(employeeEndpoints.getCount);
  const {
    data: orgData,
    userData,
    refetchOrg,
    refetchUser,
  } = useOrganizationContext();
  // const {} = useP
  const { IMG_URL } = constant;
  const {
    loading,
    error,
    put: updateUserOrg,
  } = usePutApi(userEndpoints.updateUserOrg);

  const navigate = useNavigate();

  const [companyDetails, setCompanyDetails] = useState({
    companyImage: "", // Can be a URL or a File
    companyName: "",
    address: "",
    country: "",
    registrationNum: "",
    sector: "",
    industry: "",
    employeeCount: "",
    avgRevenue: "",
  });

  const [ownerDetails, setOwnerDetails] = useState({
    ownerImage: "",
    name: "",
    email: "",
    mobile: "",
  });

  const [initialOwnerData, setInitialOwnerData] = useState(null);
  const [initialCompanyData, setInitialCompanyData] = useState(null);

  const [industryList, setIndustryList] = useState([]);

  const [openCropper, setOpenCropper] = useState(false); // State to control the cropper modal
  const [currentImageType, setCurrentImageType] = useState(null); // Whether cropping company or owner image
  const [imageToCrop, setImageToCrop] = useState(null); // URL of the image being cropped

  const [croppedCompanyImage, setCroppedCompanyImage] = useState(null); // Cropped image file for the company
  const [croppedOwnerImage, setCroppedOwnerImage] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const { deleteAcc } = useAuth();


  const [formErrors, setFormErrors] = useState({});

  const fetchIndustries = async (id) => {
    const res = await industryApis.getIndustriesById(id);
    setIndustryList(res);
  };

  useEffect(() => {
    if (orgData) {
      const initialDetails = {
        companyImage: orgData?.logo ? `${IMG_URL}/${orgData?.logo}` : "",
        companyName: orgData?.name,
        country: orgData?.country?.id,
        sector: orgData?.sector?.id,
        industry: orgData?.industry?.id,
        employeeCount: orgData?.employeeCount?.id,
        avgRevenue: orgData?.averageRevenue?.id,
        address: orgData?.address,
        registrationNum: orgData?.registrationNumber || "",
      };
      setCompanyDetails(initialDetails);
      setInitialCompanyData(initialDetails); // Set initial values
      fetchIndustries(orgData?.sector?.id);
    }
  }, [orgData]);

  // Set owner details state from userData
  useEffect(() => {
    if (userData) {
      const initialOwner = {
        name: userData?.name,
        email: userData?.email,
        mobile: userData?.phone || "",
        ownerImage:
          userData?.profileImage == "null" ||
            userData?.profileImage == null ||
            userData?.profileImage == ""
            ? ""
            : `${IMG_URL}/${userData?.profileImage}`,
      };
      setOwnerDetails(initialOwner);
      setInitialOwnerData(initialOwner); // Set initial values
    }
  }, [userData]);

  // const handleCompanyInputChange = async (e) => {
  //   if (e.target.name === "sector") {
  //     fetchIndustries(e.target.value);
  //   }
  //   setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
  // };
  const handleCompanyInputChange = async (e) => {
    const { name, value } = e.target;

    if (name === "registrationNum") {
      // Allow only alphanumeric and hyphen
      const sanitizedValue = value.replace(/[^a-zA-Z0-9-]/g, "");
      // Limit to 20 characters
      const truncatedValue = sanitizedValue.slice(0, 20);

      setCompanyDetails((prev) => ({
        ...prev,
        [name]: truncatedValue,
      }));
    } else if (name === "sector") {
      fetchIndustries(value);
      setCompanyDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setCompanyDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // const handleOwnerInputChange = (e) => {
  //   setOwnerDetails({ ...ownerDetails, [e.target.name]: e.target.value });
  // };

  const handleOwnerInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      // Remove any non-numeric characters
      const numericValue = value.replace(/[^0-9]/g, "");
      // Limit to 10 digits
      const truncatedValue = numericValue.slice(0, 10);

      setOwnerDetails((prev) => ({
        ...prev,
        [name]: truncatedValue,
      }));
    } else {
      setOwnerDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const haveFieldsChanged = () => {
    return (
      JSON.stringify(companyDetails) !== JSON.stringify(initialCompanyData) ||
      JSON.stringify(ownerDetails) !== JSON.stringify(initialOwnerData)
    );
  };

  const areAllFieldsFilled = () => {
    // Check required fields for company details
    const requiredCompanyFields = [
      "companyName",
      "country",
      "sector",
      "industry",
      "employeeCount",
      "avgRevenue",
    ];
    const allRequiredCompanyFieldsFilled = requiredCompanyFields.every(
      (field) => companyDetails[field] !== ""
    );

    // Check required fields for owner details
    const requiredOwnerFields = ["name", "email"];
    const allRequiredOwnerFieldsFilled = requiredOwnerFields.every(
      (field) => ownerDetails[field] !== ""
    );

    return allRequiredCompanyFieldsFilled && allRequiredOwnerFieldsFilled;
  };

  const handleSaveData = async (e) => {
    const emailRegex = REGEX_PATTERNS.EMAIL;
    e.preventDefault();
    const isValidEmail = emailRegex.test(ownerDetails?.email);
    if (!isValidEmail)
      setFormErrors({ ...formErrors, email: "Email is invalid!" });
    else {
      setFormErrors({});
      // Create final object for API call
      const finalData = {
        company: {
          ...companyDetails,
          companyImage: croppedCompanyImage
            ? croppedCompanyImage // Use cropped image if available
            : companyDetails.companyImage, // Use original image URL otherwise
        },
        owner: {
          ...ownerDetails,
          ownerImage: croppedOwnerImage // You can do the same for owner image if necessary
            ? croppedOwnerImage
            : ownerDetails.ownerImage,
        },
      };
      console.log(finalData);

      const apiData = {
        user_name: finalData.owner.name,
        user_profileImage: finalData?.owner?.ownerImage,
        user_phone: finalData?.owner?.mobile,
        organization_name: finalData?.company?.companyName,
        organization_logo: finalData?.company?.companyImage,
        organization_employeeCount: finalData?.company?.employeeCount,
        organization_country: finalData?.company?.country,
        organization_averageRevenue: finalData?.company?.avgRevenue,
        organization_sector: finalData?.company?.sector,
        organization_industry: finalData?.company?.industry,
        organization_address: finalData?.company?.address || "",
        // organization_registration_number: ""
        organization_registration_number:
          finalData?.company?.registrationNum || "",
      };

      if (
        (typeof apiData?.organization_logo === "string" &&
          apiData?.organization_logo?.includes(IMG_URL)) ||
        apiData?.organization_logo == ""
      ) {
        delete apiData?.organization_logo;
      }

      if (
        (typeof apiData?.user_profileImage === "string" &&
          apiData?.user_profileImage?.includes(IMG_URL)) ||
        apiData?.user_profileImage == ""
      ) {
        delete apiData?.user_profileImage;
      }

      const formData = new FormData();
      // Append each key-value pair from apiData to the FormData object
      Object.entries(apiData).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      await updateUserOrg(formData).then((res) => {
        if (res?.status === 200) {
          // alert("Profile updated");
          refetchOrg();
          refetchUser();
        }
      });
    }
  };

  const handleImageClick = (type) => {
    setCurrentImageType(type); // Set which image (company or owner) is being cropped
    setImageToCrop(
      type === "company" ? companyDetails.companyImage : ownerDetails.ownerImage
    ); // Set the image URL to crop
    setOpenCropper(true); // Open the cropper modal
  };

  const handleNewImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (e.target.files[0].size > 5 * 1024 * 1024) {
      alert("File size should not exceed 5MB.");
      // e.target.value = ""; // Reset the file input
      return;
    }
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setCurrentImageType(type);
      setImageToCrop(imageURL);
      setOpenCropper(true); // Open cropper for new image
    }
  };



  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      let response = await deleteAcc();
      if (response) {
        localStorage.clear();
        navigate('/login')
        console.log(response)
      }
    } catch (error) {
      // console.log(error?.response?.data?.message)
      console.log(error)
    }
  }


  return (
    <div className="manage-account_container">
      <div className="manage-account_heading-div">
        <img
          style={{ cursor: "pointer" }}
          src={back}
          alt="Back"
          height={16}
          width={16}
          onClick={() => navigate("/")}
        />
        <h1>Manage Account</h1>
      </div>

      <div className="account-details_container">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Company Details</h2>
          <button className="deleteBtn" onClick={handleDialogOpen}>Delete My Account</button>
        </div>

        <form onSubmit={handleSaveData}>
          <div className="company-details_form">
            <div className="account-details_upload-div">
              <img
                src={companyDetails?.companyImage || defaultOrg}
                height={70}
                width={70}
                alt="Company Logo"
                style={{ cursor: "pointer", borderRadius: "100%" }}
                onClick={() => {
                  if (
                    companyDetails?.companyImage !== null &&
                    companyDetails?.companyImage !== ""
                  ) {
                    handleImageClick("company");
                  }
                }} // Open image cropper for company image
              />

              <label className="account-details_upload-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleNewImageUpload(e, "company")} // Handle new image upload for company
                  style={{ display: "none" }}
                />
                <img src={uploadIcon} height={24} width={24} alt="Upload" />
                <span>
                  {companyDetails?.companyImage ? "Re-upload" : "Upload"}
                </span>
              </label>
            </div>
            <Grid container spacing={2.5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="companyName"
                  label="Company Name"
                  variant="outlined"
                  required
                  value={companyDetails.companyName}
                  onChange={handleCompanyInputChange}
                  sx={textFieldStyles}
                  placeholder="Enter Company Name"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="address"
                  label="Address"
                  variant="outlined"
                  // required
                  value={companyDetails.address}
                  onChange={handleCompanyInputChange}
                  sx={textFieldStyles}
                  placeholder="Enter Address"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="sector-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Country
                  </InputLabel>
                  <Select
                    required
                    name="country"
                    labelId="country-select"
                    id="demo-country-select"
                    label="Country"
                    value={companyDetails.country}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {countryData?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {/* <TextField
                  fullWidth
                  name="registrationNum"
                  label="Registration Number"
                  variant="outlined"
                  // required
                  value={companyDetails.registrationNum}
                  onChange={handleCompanyInputChange}
                  sx={textFieldStyles}
                  placeholder="Registration number"
                /> */}
                <TextField
                  fullWidth
                  name="registrationNum"
                  label="Registration Number"
                  variant="outlined"
                  value={companyDetails.registrationNum}
                  onChange={handleCompanyInputChange}
                  sx={{
                    ...textFieldStyles,
                    "& .MuiOutlinedInput-root": {
                      ...textFieldStyles["& .MuiOutlinedInput-root"],
                      height: "44px",
                      "& input": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        padding: "10px 14px",
                        fontSize: "14px", // Added to match other fields
                        lineHeight: "1.4375em", // Added for consistency
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)", // Consistent border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.87)", // Consistent hover border color
                    },
                  }}
                  placeholder="Enter registration number"
                  inputProps={{
                    maxLength: 20,
                    style: {
                      height: "100%", // Ensures input takes full height
                      boxSizing: "border-box", // Prevents padding issues
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="sector-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Sector
                  </InputLabel>
                  <Select
                    name="sector"
                    labelId="sector-select"
                    id="demo-simple-select"
                    label="Sector"
                    value={companyDetails.sector}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                    required
                  >
                    <MenuItem disabled value={""} sx={{ fontFamily: "Inter" }}>
                      Select Industry
                    </MenuItem>
                    {sectorList?.map((item, index) => (
                      <MenuItem
                        disabled={
                          item?.name !== "Extractives & Minerals Processing"
                            ? true
                            : false
                        }
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="industry-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Industry
                  </InputLabel>
                  <Select
                    required
                    name="industry"
                    labelId="industry-select"
                    id="demo-industry-select"
                    label="Industry"
                    value={companyDetails.industry}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    <MenuItem disabled value={""} sx={{ fontFamily: "Inter" }}>
                      Select Industry
                    </MenuItem>
                    {industryList?.map((item, index) => (
                      <MenuItem
                        disabled={
                          item?.name !== "Oil & Gas – Exploration & Production"
                            ? true
                            : false
                        }
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="employee-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Employee Count
                  </InputLabel>
                  <Select
                    name="employeeCount"
                    labelId="employee-select"
                    id="employee-select"
                    label="Employee Count"
                    value={companyDetails.employeeCount}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                    required
                  >
                    {employeeCount?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="revenue-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      mr: "20px",

                      // pr : "50px",

                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Annual Revenue
                  </InputLabel>
                  <Select
                    required
                    name="avgRevenue"
                    labelId="revenue-select"
                    id="demo-revenue-select"
                    label="Annual Revenue"
                    value={companyDetails.avgRevenue}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {revenueData?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <div className="owner-details_form">
            <h2>Owner Details</h2>
            <div className="account-details_upload-div">
              <img
                src={ownerDetails?.ownerImage || defaultUser}
                height={70}
                width={70}
                alt="Owner Image"
                style={{ cursor: "pointer", borderRadius: "100%" }}
                onClick={() => {
                  if (
                    ownerDetails?.ownerImage !== null &&
                    ownerDetails?.ownerImage !== ""
                  ) {
                    handleImageClick("owner");
                  }
                }} // Open image cropper for owner image
              />

              <label className="account-details_upload-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleNewImageUpload(e, "owner")} // Handle new image upload for owner
                  style={{ display: "none" }}
                />
                <img src={uploadIcon} height={24} width={24} alt="Upload" />
                {ownerDetails?.ownerImage ? "Re-upload" : "Upload"}
              </label>
            </div>
            <Grid container spacing={2.5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  variant="outlined"
                  required
                  value={ownerDetails.name}
                  onChange={handleOwnerInputChange}
                  sx={textFieldStyles}
                  placeholder="Name"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  name="email"
                  label="Email"
                  variant="outlined"
                  required
                  value={ownerDetails.email}
                  onChange={handleOwnerInputChange}
                  sx={textFieldStyles}
                  placeholder="Email"
                  error={!!formErrors?.email}
                  helperText={formErrors?.email}
                />
              </Grid>
              <Grid item xs={4}>
                {/* <TextField
                  fullWidth
                  name="mobile"
                  label="Mobile"
                  variant="outlined"
                  // required
                  value={ownerDetails.mobile}
                  onChange={handleOwnerInputChange}
                  sx={textFieldStyles}
                  placeholder="Mobile No."
                /> */}
                <TextField
                  fullWidth
                  name="mobile"
                  label="Mobile"
                  variant="outlined"
                  value={ownerDetails.mobile}
                  onChange={handleOwnerInputChange}
                  sx={{
                    ...textFieldStyles,
                    "& .MuiOutlinedInput-root": {
                      ...textFieldStyles["& .MuiOutlinedInput-root"],
                      height: "44px",
                    },
                  }}
                  placeholder="Enter 10 digit mobile number"
                  inputProps={{
                    maxLength: 10,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  error={
                    ownerDetails.mobile !== "" &&
                    ownerDetails.mobile.length !== 10
                  }
                  helperText={
                    ownerDetails.mobile !== "" &&
                      ownerDetails.mobile.length !== 10
                      ? "Mobile number must be 10 digits"
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </div>

          <div style={{ float: "right", marginBottom: "20px" }}>
            {/* <button
              type="submit"
              disabled={!areAllFieldsFilled() && formErrors}
              className="account-details_save-btn"
            >
              Save
            </button> */}
            <GradientButton
              type="submit"
              disabled={
                (!areAllFieldsFilled() && formErrors) ||
                loading ||
                !haveFieldsChanged()
              }
            >
              {loading ? "Saving" : "Save"}
            </GradientButton>
          </div>
        </form>
      </div>

      <LogoutDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDelete}
        text='Do you want to Delete?'
        subText='By deleting your account, all your data will be permanently removed and cannot be recovered.'
      />

      <ImageCropper
        open={openCropper}
        setOpen={setOpenCropper}
        imageUrl={imageToCrop}
        setImageApi={(croppedImageUrl) => {
          if (currentImageType === "company") {
            setCompanyDetails((prev) => ({
              ...prev,
              companyImage: croppedImageUrl,
            }));
          } else {
            setOwnerDetails((prev) => ({
              ...prev,
              ownerImage: croppedImageUrl,
            }));
          }
        }}
        setCroppedImage={(croppedFile) => {
          if (currentImageType === "company") {
            setCroppedCompanyImage(croppedFile);
          } else {
            setCroppedOwnerImage(croppedFile);
          }
        }}
      />
    </div>
  );
};

export default ManageAccount;
