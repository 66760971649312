import React, { useState, useRef } from "react";
import { Button } from "@mui/material";
import "./sustainabilityIndex.css";
import GovernanceStats from "./GovernanceStats";
import BaseSectorStats from "./BaseSectorStats";
import EnvironmentalStats from "./EnvironmentalStats";
import SocialStats from "./SocialStats";
import logo from "../../assets/images/logo.png";
import expert from "../../assets/expert.svg";
import close from "../../assets/close.svg";
import { useNavigate, useParams } from "react-router-dom";
import { reportEndpoints } from "../../api/endpoints/report.endpoints";
import useFetch from "../../utils/hooks/useFetch";
import GradientButton from "../common/GradientButton";
import defaultOrg from "../../assets/defaultOrg.svg";
import OutlinedButton from "../common/OutlinedButton";
import constant from "../../constant";
import usePostApi from "../../utils/hooks/usePost";
import usePutApi from "../../utils/hooks/usePutApi";
import BaseSectorZeroState from "./zeroState/BaseSectorZeroState";
import EnvironmentalZeroState from "./zeroState/EnvironmentalZeroState";
import SocialZeroState from "./zeroState/SocialZeroState";
import GovernanceZeroState from "./zeroState/GovernanceZeroState";
import certified from "../../assets/images/certifiedLogo.svg";
import Loader from "../Loader";
import FileImg from "../../assets/File.svg";
import UploadImg from "../../assets/UploadFile.svg";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  fetchSubscriptionPlan,
  activateSubscriptionPlan,
} from "../../api/subscription";
import { addSupportingDocument, downloadSupportingDocument } from "../../api/reportApis";

const PaymentForm = ({ onClose }) => {
  const params = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/score-card/${params?.id}`,
      },
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    }
  };

  return (
    <div className="payment-overlay">
      <div className="payment-modal">
        <div className="payment-modal-header">
          <h3 style={{ fontWeight: "500" }}>
            Upgrade to Premium Plan for Vetting Reports
          </h3>
          <button onClick={onClose} className="close-button">
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          {error && <div className="error-message">{error}</div>}
          <button
            type="submit"
            disabled={!stripe || processing}
            style={{ marginTop: "20px" }}
            className="your-subscription-get_started_filled"
          >
            {processing ? "Processing..." : "Pay Now"}
          </button>
        </form>
      </div>
    </div>
  );
};

const SustainabilityIndex = () => {
  const params = useParams();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showExpert, setShowExpert] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const fileInput = useRef(null);
  const newUploadRef = useRef(null);

  const {
    data: reportData,
    loading,
    error,
    refetch: refetchReport,
  } = useFetch(reportEndpoints.getReport(params?.id));

  const {
    data: statusResponse,
    error: updateStatusErr,
    loading: updatingStatus,
    put: updateStatus,
  } = usePutApi(reportEndpoints.updateReportStatus(params?.id));

  const navigate = useNavigate();

  const disclaimer = [
    "The scorecard provides estimates based on methodology and data inputs at the time; accuracy is dependent on input quality. Standards and best practices continually evolve.",
    "Scorecard comparisons and benchmarks should be interpreted within broader organizational contexts along with other due diligence. Unique circumstances apply.The tool provides generalized comparisons to industry average",
    "While aiming for completeness, assessments have inherent constraints. Users assume responsibility for sound interpretation and application of results.",
    "No warranty is given on the fitness of the sustainability scorecard for any particular purpose. The scorecard should not be relied upon to make acquisition, divestment, investment, hiring, or other decisions without supplementary diligence",
    "The proprietary SI index methodology by GE3S takes into account only broad industrial sectors, specific sectors or clients who might have additional parameters that need to be monitored should reach out to ge3s via email/purchasing the essentials plan",
  ];

  const saveReportAsReady = async () => {
    await updateStatus({ status: "ready" })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  const handlePaymentSetup = async () => {
    try {
      setStripeLoading(true);
      const plansResponse = await fetchSubscriptionPlan();
      if (!plansResponse.data?.data) {
        throw new Error("No subscription plans available");
      }

      const verificationPlan = plansResponse.data.data.find(
        (plan) => plan.features.numberOfVettiedReports > 0
      );

      if (!verificationPlan) {
        throw new Error("Verification plan not found");
      }

      const response = await activateSubscriptionPlan(verificationPlan.id);
      if (response.data?.data) {
        const { publishableKey, clientSecret } = response.data.data;

        if (!publishableKey || typeof publishableKey !== "string") {
          throw new Error("Invalid publishable key received from server");
        }

        setStripeOptions({
          stripe: await loadStripe(publishableKey),
          options: {
            clientSecret,
            appearance: {
              theme: "stripe",
            },
          },
        });
        setShowPaymentForm(true);
      }
    } catch (error) {
      console.error("Error setting up payment:", error);
      alert("Failed to setup payment. Please try again.");
    } finally {
      setStripeLoading(false);
    }
  };

  const sendForVerification = async () => {
    await updateStatus({ status: "sendForVerification" })
      .then((res) => {
        console.log(res?.data?.report?.supportingDocuments)
        // Only add supporting documents if they're not already in the array
        const existingDocs = res?.data?.report?.supportingDocuments || [];
        const formattedDocs = existingDocs.map(doc => ({
          name: doc.fileName || doc.name,
          isExisting: true,
          key: doc.key,
          // preserve other metadata you might need
        }));
        setSelectedFiles((prev) => [...prev, ...formattedDocs]);
        setShowConfirmModal((prev) => !prev);
      })
      .catch((err) => {
        handlePaymentSetup();
      });
  };

  const handleConfirmVetting = async () => {
    try {
      const formData = new FormData();

      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });

      console.log("selected files", selectedFiles);
      console.log("Files to upload:", formData);

      const response = await addSupportingDocument(params.id, formData);
      console.log("uploaded response", response);
      console.log("File Uploaded successfully");
      await sendForVerification();
      setSelectedFiles([]);
      setShowSuccessModal(true);

    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files. Please try again.");
    }
  };

  const handleNavigateToDataPoints = () => {
    navigate(`/add-data-points/${params?.id}`);
  };

  const handleNavigateToDashboard = () => {
    setShowSuccessModal(false);
    navigate('/');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles((prev) => [...prev, ...files]);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prev) => [...prev, ...files]);
    // Reset the input value so the same file can be selected again
    e.target.value = '';
  };

  const removeFile = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleFileUpload = async () => {

  };

  const handleUploadClick = () => {
    fileInput.current && fileInput.current.click();
  };


  const handleFileDownload = async (file) => {
    if (!file?.key) return; // Only download existing files

    try {
      const response = await downloadSupportingDocument(params.id, file?.key);

      // Create a blob from the response data
      const blob = new Blob([response], { type: 'application/pdf' });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = url;
      link.download = `${file.name}`; // Set the download filename

      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file. Please try again.');
    }
  };


  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <div className="sustainability-top-heading">
        <h1>Sustainability Index Score Card</h1>
      </div>

      {!error ? (
        <>
          {!loading ? (
            <div className="charts-container">
              <div className="sustainability-heading_container">
                <div className="sustainability-heading">
                  <img
                    src={
                      reportData?.report?.organizationDetails?.organization
                        ?.logo
                        ? `${constant.IMG_URL}/${reportData?.report?.organizationDetails?.organization?.logo}`
                        : defaultOrg
                    }
                    height={55}
                    width={55}
                    alt="Company Logo"
                    style={{
                      border: "1px solid #ace6e6",
                      borderRadius: "100%",
                    }}
                  />
                  <div className="sustainability-heading_title">
                    <h1>
                      {
                        reportData?.report?.organizationDetails?.organization
                          ?.name
                      }
                    </h1>{" "}
                    <span>|</span>{" "}
                    <h2>
                      Sustainability Index For {reportData?.report?.segment},{" "}
                      {reportData?.report?.year}
                    </h2>
                  </div>
                </div>
              </div>

              {reportData?.report?.last6YearsReports?.length > 0 ? (
                <>
                  <BaseSectorStats report={reportData?.report} />
                  <EnvironmentalStats
                    report={reportData?.report}
                    cleanElectricityFactor={
                      reportData?.cleanElectricityFactor / 100
                    }
                  />
                  <SocialStats report={reportData?.report} />
                  <GovernanceStats report={reportData?.report} />
                </>
              ) : (
                <>
                  <BaseSectorZeroState report={reportData?.report} />
                  <EnvironmentalZeroState
                    report={reportData?.report}
                    cleanElectricityFactor={
                      reportData?.cleanElectricityFactor / 100
                    }
                  />
                  <SocialZeroState report={reportData?.report} />
                  <GovernanceZeroState report={reportData?.report} />
                </>
              )}

              <div
                className={`disclaimer-div ${reportData?.report?.status === "vetted" ? "div-margin" : ""
                  }`}
              >
                <h3>Disclaimers</h3>
                <ul>
                  {disclaimer.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <Loader />
          )}

          {showConfirmModal && (
            <div className="modal-overlay">
              <div className="modal-content ">
                <img src={FileImg} alt="File" />
                <p>Provide the supporting documents to validate the data.</p>

                {selectedFiles.length > 0 ? (
                  <>
                    <div
                      className="selected-files-container"
                      style={{
                        backgroundColor: "rgba(244, 255, 252, 1)",
                        padding: "20px",
                        borderRadius: "8px",
                        minHeight: "200px",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        className="files-list"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {selectedFiles.map((file, index) => (
                          <div
                            key={index}
                            className="file-item"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              padding: "10px",
                              backgroundColor: "white",
                              margin: "8px 4px",
                              borderRadius: "4px",
                              position: "relative"
                            }}
                          >
                            <div
                              onClick={() => file?.isExisting && handleFileDownload(file)}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                cursor: file?.isExisting ? "pointer" : "default",
                                gap: "10px",
                                width: "180px",
                              }}
                            >
                              <div style={{ position: "relative" }}>
                                <img src={UploadImg} alt="File" />
                                {!file?.isExisting && (
                                  <button
                                    onClick={() => removeFile(index)}
                                    style={{
                                      position: "absolute",
                                      top: "0px",
                                      right: "0px",
                                      border: "1px solid #ff4444",
                                      background: "white",
                                      cursor: "pointer",
                                      color: "#ff4444",
                                      borderRadius: "50%",
                                      width: "14px",
                                      height: "14px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0",
                                      fontSize: "12px",
                                      lineHeight: "0",
                                      zIndex: 1
                                    }}
                                  >
                                    ×
                                  </button>
                                )}
                              </div>
                              <span
                                style={{
                                  color: "rgba(160, 160, 160, 1)",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {file.name}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                    </div>
                    <div className="modal-buttons">
                      <button
                        className="btn-no_borders"
                        onClick={() => {
                          setShowConfirmModal(false);
                          navigate('/');  // Navigate to dashboard
                        }}
                      >
                        Skip
                      </button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (selectedFiles.length > 0) {
                            // handleFileInputClick();
                            newUploadRef.current && newUploadRef.current.click();
                          }
                          // else {
                          //   setShowUploadedFile(false);
                          // }
                        }}
                        sx={{
                          padding: "6px 28px",
                          fontWeight: 400,
                          fontSize: "14px",
                          fontFamily: "Inter",
                          borderRadius: "6px",
                          border: "1px solid var(--G2, #369D9C)",
                          textTransform: "none",
                          background:
                            "linear-gradient(102deg, #369D9C 0%, #28814D 100%)",
                          backgroundClip: "text",
                          "-webkitBackgroundClip": "text",
                          "-webkitTextFillColor": "transparent",
                          ":hover": {
                            border: "1px solid var(--G2, #369D9C)",
                            color: " #005468",
                          },
                        }}
                      >
                        <input
                          ref={newUploadRef}
                          type="file"
                          multiple
                          onChange={handleFileSelect}
                          style={{ display: "none" }}
                        />
                        Upload
                      </Button>
                      {selectedFiles.length > 0 && selectedFiles.some(file => !file.isExisting) && (
                        <GradientButton
                          onClick={handleConfirmVetting}
                          height={"45px"}
                          fontSize={"0.875rem"}
                        >
                          Submit
                        </GradientButton>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`file-upload-area ${isDragging ? "dragging" : ""
                        }`}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      style={{ backgroundColor: "rgba(244, 255, 252, 1)" }}
                    >
                      <input
                        ref={fileInput}
                        type="file"
                        multiple
                        onChange={handleFileSelect}
                        id="file-input"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-input" className="file-upload-label">
                        <p
                          style={{
                            color: "rgba(36, 38, 52, 1)",
                            fontSize: "12px",
                            fontWeight: "400",
                            paddingTop: "40px",
                            paddingBottom: "40px",
                            paddingLeft: "100px",
                            paddingRight: "100px",
                          }}
                        >
                          Click to browse or drag and drop your CSV/Excel files
                        </p>
                        <p
                          style={{
                            color: "rgba(36, 38, 52, 1)",
                            fontSize: "8px",
                            fontWeight: "400",
                            paddingTop: "40px",
                            paddingBottom: "40px",
                            paddingLeft: "100px",
                            paddingRight: "100px",
                          }}
                        >
                          ( Uploading file size limit : 15 mb )
                        </p>
                      </label>
                    </div>
                    <div className="modal-buttons">
                      <button
                        className="btn-no_borders"
                        onClick={() => {
                          setShowConfirmModal(false);
                          navigate('/');  // Navigate to dashboard
                        }}
                      >
                        Skip
                      </button>
                      <Button
                        variant="outlined"
                        onClick={handleUploadClick}
                        sx={{
                          padding: "6px 28px",
                          fontWeight: 400,
                          fontSize: "14px",
                          fontFamily: "Inter",
                          borderRadius: "6px",
                          border: "1px solid var(--G2, #369D9C)",
                          textTransform: "none",
                          background:
                            "linear-gradient(102deg, #369D9C 0%, #28814D 100%)",
                          backgroundClip: "text",
                          "-webkitBackgroundClip": "text",
                          "-webkitTextFillColor": "transparent",
                          ":hover": {
                            border: "1px solid var(--G2, #369D9C)",
                            color: " #005468",
                          },
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {!loading &&
            (reportData?.report?.status === "vetted" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "3rem",
                }}
              >
                <img src={certified} alt="Certified" height={120} width={180} />
              </div>
            ) : (
              <div className="sustainability-bottom_div-container">
                <div className="sustainability-bottom_div1">
                  <button
                    disabled={
                      reportData?.report?.status === "sendForVerification" ||
                      reportData?.report?.status === "ready"
                    }
                    className="btn-no_borders"
                    onClick={saveReportAsReady}
                  >
                    Save as Ready
                  </button>
                  <button
                    onClick={handleNavigateToDataPoints}
                    className="btn-no_borders"
                    disabled={
                      reportData?.report?.status === "sendForVerification"
                    }
                  >
                    Edit Data Points
                  </button>
                  <GradientButton
                    onClick={sendForVerification}
                    height={"45px"}
                    fontSize={"0.875rem"}
                    disabled={stripeLoading}
                  >
                    {stripeLoading ? "Processing..." : "Send for Vetting"}
                  </GradientButton>
                </div>
              </div>
            ))}

          {showPaymentForm && stripeOptions && (
            <Elements
              stripe={stripeOptions.stripe}
              options={stripeOptions.options}
            >
              <PaymentForm onClose={() => setShowPaymentForm(false)} />
            </Elements>
          )}

          {showSuccessModal && (
            <div className="modal-overlay" onClick={() => setShowSuccessModal(false)}>
              <div className="modal-content" onClick={e => e.stopPropagation()} style={{ maxWidth: "400px" }}>
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "12px" }}>
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="32" cy="32" r="32" fill="#E8F5E9" />
                    <path d="M27.5 38.7L19.3 30.5L16.5 33.3L27.5 44.3L47.5 24.3L44.7 21.5L27.5 38.7Z" fill="#4CAF50" />
                  </svg>
                  <p style={{ margin: '20px 0', fontSize: '16px', color: '#333' }}>Request has been submitted successfully.</p>
                  <GradientButton
                    onClick={handleNavigateToDashboard}
                    height={"45px"}
                    fontSize={"0.875rem"}
                  >
                    Go to Dashboard
                  </GradientButton>
                </div>
              </div>
            </div>
          )}
        </>
      ) : error?.response?.status === 401 ? (
        <p>You are not authorized to access this page! Please login first.</p>
      ) : (
        <p>Error loading data</p>
      )}

      <style jsx>{`
        .payment-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .payment-modal {
          background: white;
          padding: 20px;
          border-radius: 8px;
          width: 100%;
          max-width: 500px;
          margin: 20px;
        }

        .payment-modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }

        .close-button {
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }

        .error-message {
          color: red;
          margin: 10px 0;
        }
      `}</style>
    </div>
  );
};

export default SustainabilityIndex;
