import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import gross from "../../../assets/sustainabilityReport/gross.svg";
import newSuppliers from "../../../assets/sustainabilityReport/newSuppliers.svg";
import newTech from "../../../assets/sustainabilityReport/newTech.svg";
import CSR from "../../../assets/sustainabilityReport/CSR.svg";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import DoughnutChart from "../DoughnutChart";
import boardMembers from "../../../assets/sustainabilityReport/boardMembers.svg";
import yesCheck from "../../../assets/sustainabilityReport/yesCheck.svg";
import noCross from "../../../assets/sustainabilityReport/noCross.svg";

const GovStatsCard = ({ icon, title, amount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#FFF5E5",
        p: "20px",
        borderRadius: "12px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={28}
        width={28}
        style={{ marginBottom: "6px" }}
      />
      <h4 className="base-sector-card_title">{title}</h4>
      <Typography
        variant="h5"
        sx={{
          fontSize: "18px",
          marginTop: "auto",
          fontWeight: 500,
          fontFamily: "Inter",
        }}
      >
        {amount?.toLocaleString("en-IN")}
      </Typography>
    </Box>
  );
};

const GovernanceZeroState = ({ report }) => {
  const { governanceKPI, basicSectorSpecificKPI, socialKPI } = report;

  const formatPercentage = (value) => {
    // If it's a whole number (like 50.00), show without decimals
    console.log(value)
    if (value % 1 === 0) {
      return Math.floor(value) + "%";
    }
    // Otherwise show with 2 decimal places
    return value.toFixed(2) + "%";
  };

  const cardData = [
    {
      id: 0,
      icon: gross,
      title: "Gross Revenue (in million USD)",
      amount: basicSectorSpecificKPI?.B1?.grossRevenue?.value
        ? basicSectorSpecificKPI.B1.grossRevenue.value.toLocaleString("en-IN")
        : '',
    },
    {
      id: 1,
      icon: gross,
      title: "Amount spent on local suppliers (in million USD)",
      amount: governanceKPI?.G3?.localSuppliersSpending?.value,
    },
    {
      id: 2,
      icon: newSuppliers,
      title: "Amount spent on new suppliers (in million USD)",
      amount: governanceKPI?.G4?.newSuppliersSpending?.value,
    },
    {
      id: 3,
      icon: newTech,
      title: "Amount spent on new/innovative technologies (in million USD)",
      amount: governanceKPI?.G6?.innovativeTechnologiesSpending?.value,
    },
    {
      id: 4,
      icon: CSR,
      title: "Amount spent on CSR activities (in million USD)",
      amount: governanceKPI?.G10?.csrSpending?.value
        ? governanceKPI.G10.csrSpending.value.toLocaleString("en-IN")
        : '',
    },
    {
      id: 5,
      icon: boardMembers,
      title: "Number of board members",
      amount:
        governanceKPI?.G9?.boardMembers?.value
          ? governanceKPI?.G9?.boardMembers?.value.toLocaleString("en-IN")
          : '',
    },
  ];

  const avgEmployeeTenureDoughnut = [
    {
      label: "Complaints Resolved",
      value: (governanceKPI?.G8?.totalComplaints?.value === 0 && governanceKPI?.G8?.resolvedComplaints?.value === 0)
        ? 1  // Show full circle when both values are missing
        : governanceKPI?.G8?.resolvedComplaints?.value || 0,
    },
    {
      label: "Total Complaints",
      value: (!governanceKPI?.G8?.totalComplaints?.value && !governanceKPI?.G8?.resolvedComplaints?.value)
        ? 0  // Show empty portion when both values are missing
        : (governanceKPI?.G8?.totalComplaints?.value || 0) - (governanceKPI?.G8?.resolvedComplaints?.value || 0),
    },
  ];

  const resolvedComplaintsPercent =
    (!governanceKPI?.G8?.totalComplaints?.value && !governanceKPI?.G8?.resolvedComplaints?.value)
      ? 100  // Show 100% when both values are missing
      : (governanceKPI?.G8?.totalComplaints?.value
        ? (governanceKPI?.G8?.resolvedComplaints?.value / governanceKPI?.G8?.totalComplaints?.value) * 100
        : 0);

  return (
    <>
      <h1 className="sustainability-section_heading">Governance & Economics Index</h1>
      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {cardData.map((item) => (
          <Grid key={item.id} item xs={2}>
            <GovStatsCard {...item} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2.5}>
        <Grid item xs={5}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              p: 2,
              backgroundColor: "#FFF",
              height: "20rem",
            }}
          >
            <h2>Percentage of complaints resolved (%)</h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <DoughnutChart
                    colors={["#FFCB77", "#FFF6E7"]}
                    data={avgEmployeeTenureDoughnut}
                    width={width}
                    height={height}
                    centerText={formatPercentage(resolvedComplaintsPercent)}
                    // subText={`Years & ${avgEmpTenure.months} months`}
                    unit="%"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              p: 2,
              backgroundColor: "#FFF",
            }}
          >
            <h2>Policies in Place</h2>
            <Grid
              container
              sx={{
                backgroundColor: "#fcfcfc",
                p: 2,
              }}
            >
              <Grid
                item
                xs={9}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                }}
              >
                Policy
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                }}
              >
                Status
              </Grid>
            </Grid>

            <Box
              sx={{
                border: "1px solid #E8E8E8",
                borderRadius: "12px",
                // height: "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={9}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2.2rem 0",
                    borderBottom: "1px solid #E8E8E8",
                    borderRight: "1px solid #E8E8E8",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                  }}
                >
                  Do you have an ethics policy in place?
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid #E8E8E8",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                  }}
                >
                  {socialKPI?.S6?.ethicsPolicyInPlace?.value ? (
                    <img src={yesCheck} height={50} width={50} alt="Yes" />
                  ) : (
                    <img src={noCross} height={50} width={50} alt="No" />
                  )}
                </Grid>

                <Grid
                  item
                  xs={9}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2.2rem 0",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    borderRight: "1px solid #E8E8E8",
                  }}
                >
                  Do you have a non-discriminatory policy in place?
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                  }}
                >
                  {socialKPI?.S6?.nonDiscriminatoryPolicy?.value ? (
                    <img src={yesCheck} height={50} width={50} alt="Yes" />
                  ) : (
                    <img src={noCross} height={50} width={50} alt="No" />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={3.5}>
          <Box
            sx={{
              p: 2,
              backgroundColor: "#fff5e5",
              display: "flex",
              alignItems: "center",
              height: "100%",
              borderRadius: "12px",
            }}
          >
            <div>
              <img src={boardMembers} height={30} width={30} alt="Clock" />
              <p
                style={{ fontSize: "1rem", fontWeight: 500, marginTop: "10px" }}
              >
                Number of board members
              </p>
              <p
                style={{ fontSize: "22px", fontWeight: 600, marginTop: "10px" }}
              >
                {governanceKPI?.G9?.boardMembers?.value?.toLocaleString(
                  "en-IN"
                )}
              </p>
            </div>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default GovernanceZeroState;
