/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, TextField } from "@mui/material";
import PasswordInput from "../Authentication/PasswordInput";
import { useNavigate } from "react-router-dom";
import pic from "../../assets/gesh.png";
import bgPattern from "../../assets/images/login/bg.svg";
import VideoPlayer from "../common/VideoPlayer";
import { useAuth } from "../context/AuthContext";
import { textFieldStyles } from "../../styles/inputStyles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { verifyPasswordWithBackend } = useAuth();
  const [error, setError] = useState({
    email: false,
    password: false,
    root: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  // Validate if the form is filled (both email and password)
  useEffect(() => {
    const isEmailValid = email.trim() !== "";
    const isPasswordValid = password.trim() !== "";

    // Set form validity based on whether fields are filled
    setIsFormValid(isEmailValid && isPasswordValid);
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      user_email: email,
      user_password: password,
    };
    try {
      let response = await verifyPasswordWithBackend(payload);
      console.log(response)
      if (response?.status === 200) {
        navigate("/");
      } else throw new Error("The email or password you entered is incorrect.");
    } catch (error) {
      // console.log(error?.response?.data?.message)
      if (error?.response?.data?.message?.includes("blocked")) {
        setError({ ...error, root: "Account Blocked, please contact Admin." });
      } else {
        setError({ ...error, root: "The email or password you entered is incorrect." });
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("resetEmail");
    localStorage.removeItem("resetToken");
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container style={{ height: "100%", width: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <VideoPlayer />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // marginTop: "5rem",
          }}
        >
          <img
            src={bgPattern}
            alt=""
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              zIndex: "1",
              width: "400px",
              height: "400px",
            }}
          />
          <Box
            style={{
              maxWidth: "420px",
              width: "100%",
              padding: "20px",
              position: "relative",
              zIndex: "2",
            }}
          >
            <img
              src={pic}
              alt="Logo"
              style={{ width: "90px", marginBottom: "15px" }}
            />
            <Typography
              variant="h1"
              gutterBottom
              style={{ fontFamily: "Inter", fontSize: "29px", fontWeight: 500 }}
            >
              Sign In to Track Your Sustainability Goals
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginTop: "16px",
                marginBottom: "2rem",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: 15,
                color: "#8692A6",
              }}
            >
              Enter your credentials to access your personalized sustainability
              dashboard.
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                required
                size="small"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error.email}
                style={{
                  marginBottom: 15,
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
                sx={textFieldStyles}
              />
              <PasswordInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={error.password}
                placeholder={"Password"}
              />

              {error.root ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    mt: "0.5rem",
                    fontWeight: 500,
                    color: "#F51934",
                  }}
                >
                  {error.root}
                </Typography>
              ) : null}

              <button
                type="submit"
                disabled={!isFormValid}
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  padding: "14px 0",
                  borderRadius: 6,
                  background: !isFormValid ? "#E8E8E8" : "",
                  backgroundImage: isFormValid
                    ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                    : "",
                  fontWeight: 500,
                  fontSize: "15px",
                  fontFamily: "Inter",
                  color: !isFormValid ? "#A2A2A2" : "#FFF",
                  border: "1px solid #DDD",
                  letterSpacing: "0.5px",
                  cursor: isFormValid ? "pointer" : "not-allowed",
                }}
              >
                Log In
              </button>

              <p
                className="login_text2"
                onClick={() =>
                  navigate("/reset-password", { state: { email } })
                }
                style={{
                  background:
                    "linear-gradient(102deg, #369d9c 0%, #28814d 100%)",
                  backgroundClip: "text",
                  webkitBackgroundClip: "text",
                  webkitTextFillColor: "transparent",
                }}
              >
                Forgot Password?
              </p>

              <p className="login_text2">
                <span>Don't have an account? </span>
                <span
                  style={{
                    background:
                      "linear-gradient(102deg, #369d9c 0%, #28814d 100%)",
                    backgroundClip: "text",
                    webkitBackgroundClip: "text",
                    webkitTextFillColor: "transparent",
                  }}
                  className="signup"
                  onClick={() => navigate("/signUp")}
                >
                  Signup
                </span>
              </p>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
