import api from ".";
import axios from "axios";

export const reportApis = {
  initiateReport: async (data) => {
    try {
      const res = await api.post("/report/initiate", data);
      return res.data;
    } catch (error) {
      throw new Error(error.response);
    }
  },
};


export const deleteReport = async (id) => {
  try {
    const response = await api.delete(`report/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting report:", error);
    throw error;
  }
};

export const addSupportingDocument = async(reportId, formData) => {
  
  try{
    const response = await api.post(`/report/add-supporting-documents/${reportId}`, formData , {
      headers: {
            'Content-Type': 'multipart/form-data',
          },
    })
    return response.data

  }catch(error){
    console.error("Error deleting report:", error);
    throw error;

  }
};



export const downloadSupportingDocument = async(reportId, key) => {
  try {
    const response = await api.get(`/report/supporting-document/${reportId}?key=${key}`, {
      responseType: 'blob',
    });
    return response.data;
  } catch(error) {
    console.error("Error downloading report:", error);
    throw error;
  }
};