import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import sent from "../../assets/inviteSent.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  Box,
  Dialog,
  DialogContent,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import Lottie from "react-lottie-player";
import confirmAnimation from "../../assets/animation/confirm.json";
import GradientButton from "../common/GradientButton";
import useOrganizationContext from "../../context/OrgContext";
import { textFieldStyles } from "../../styles/inputStyles";
import { scheduleCalls } from "../../api/calls";
import { useNavigate } from "react-router-dom";

function BookCall({ open, onClose }) {
  const navigate = useNavigate();
  const today = dayjs(); // Get today's date
  const [selectedDate, setSelectedDate] = useState(today.format("DD/MM/YYYY")); // Initialize with today's date in the desired format
  const [displayDate, setDisplayDate] = useState(today.format("ddd, MMM D"));
  const [currentState, setCurrentState] = useState("selectDate");
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [mobile, setMobile] = useState("");
  const [selectedTime24hr, setSelectedTime24hr] = useState("");
  const { data: orgData, userData } = useOrganizationContext();
  console.log(userData);

  const timeOptions = [
    "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM",
    "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM",
    "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM",
    "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM",
    "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM",
    "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM",
    "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
  ];

  const handleTimeChange = (event) => {
    const time12hr = event.target.value;
    // Convert 12-hour format to 24-hour format
    const [time, period] = time12hr.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours);
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
    const time24hr = `${hours.toString().padStart(2, '0')}:${minutes}`;
    setSelectedTime24hr(time24hr);
  };


  // Helper function to convert 24hr to 12hr for display
  const getDisplayTime = (time24hr) => {
    if (!time24hr) return '';
    const [hours, minutes] = time24hr.split(':');
    const hour = parseInt(hours);
    const period = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 || 12;
    return `${displayHour}:${minutes} ${period}`;
  };

  const handleDateChange = (date) => {
    addDisplayDate(date);
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setSelectedDate(formattedDate);
  };
  const addDisplayDate = (date) => {
    const formattedDate = dayjs(date).format("ddd, MMM D");
    setDisplayDate(formattedDate);
  };

  useEffect(() => {
    if (userData?.phone?.trim() !== "") {
      setMobile(userData?.phone);
      setEnableConfirm(true);
    } else {
      setEnableConfirm(false);
    }
  }, [displayDate]);

  useEffect(() => {
    setCurrentState("selectDate");
  }, [open]);

  const handleConfirm = async () => {
    const payload = {
      name: userData?.name,
      email: userData?.email,
      phone: mobile,
      date: selectedDate,
      time: selectedTime24hr,
    };
    let response = await scheduleCalls(payload);
    // console.log(response);
    if (response?.status === 201) {
      setCurrentState("confirmPopup");
    } else {
      alert("Something went wrong");
    }
  };

  const handleGoToDashboard = () => {
    setSelectedTime24hr("");
    setMobile("");  
    onClose();
    navigate("/");
  };

  return (
    <Dialog
      open={open}
      onClose={()=>{setSelectedTime24hr(""); setMobile(""); onClose()}}
      sx={{
        "& .MuiDialog-paper": {
          width: "460px", // Set the desired width
          maxWidth: "100%", // Optional: Ensure it doesn't exceed the viewport
          borderRadius: "12px", // Optional: Adjust border radius if needed
        },
      }}
    >
      <DialogContent>
        {currentState === "selectDate" && (
          <>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, fontFamily: "Inter" }}
            >
              Schedule a Call
            </Typography>
            <Typography
              sx={{ fontFamily: "Inter", fontSize: "12px", margin: ".5rem 0" }}
            >
              Please select a date for your call
            </Typography>
            <Box
              sx={{
                border: "1px solid  #CAC4D0",
                borderRadius: "8px",
                mt: "1rem",
                // width: "320px",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid #CAC4D0",
                  padding: ".5rem 1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    margin: "0 0 0.7rem 0",
                    fontWeight: 500,
                  }}
                >
                  Select date
                </Typography>

                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Inter",
                    color: "#1D1B20",
                    fontWeight: 500,
                  }}
                >
                  {displayDate}
                </Typography>
              </Box>
              <Box style={{ maxHeight: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    disablePast
                    onChange={handleDateChange}
                    sx={{
                      "&.MuiDateCalendar-root": {
                        // backgroundColor: "red",
                        width: "100% !important",
                        // maxWidth: "280px", // Adjust width
                        maxHeight: "300px",
                      },
                      "&.MuiDayCalendar-header": {
                        width: "100% !important",
                      },
                      "&.MuiDateCalendar-root .MuiDayCalendar-header": {
                        justifyContent: "space-evenly",
                        fontSize: "18px !important",
                      },
                      "&.MuiDateCalendar-root .MuiDayCalendar-weekContainer": {
                        justifyContent: "space-evenly !important",
                        fontSize: "18px !important",
                      },
                      "&.MuiDateCalendar-root .MuiDayCalendar-weekContainer span":
                        {
                          fontSize: "18px !important",
                        },

                      ".MuiPickersDay-today": {
                        border: "1px solid #2B8860 !important",
                        color: "#2B8860 !important",
                      },
                      ".Mui-selected": {
                        backgroundColor: "#2B8860 !important",
                        color: "#fff !important",
                      },

                      //   ".MuiDayCalendar-header": {
                      //     marginTop: "0.5rem !important",
                      //   },
                      //   ".MuiDayCalendar-weekContainer": {
                      //     width: "100% !important",
                      //     gap: "0.5rem !important", // Reduce gap between dates
                      //     marginTop: "0.25rem !important", // Reduce top margin
                      //     marginBottom: "0.25rem !important", // Reduce bottom margin
                      //   },
                      //   ".MuiPickersDay-root": {
                      //     fontSize: "12px", // Reduce font size of the days
                      //     width: "28px", // Adjust width of each day cell
                      //     height: "28px", // Adjust height of each day cell
                      //   },
                      //   ".MuiDayCalendar-weekDayLabel": {
                      //     fontSize: "12px", // Reduce font size of the week day labels
                      //     height: "20px", // Adjust height of week day labels
                      //   },
                      //   ".MuiPickersCalendarHeader-root": {
                      //     minHeight: "30px !important", // Reduce header height
                      //   },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => setCurrentState("callConfirm")}
              disabled={!selectedDate}
              fullwidth
              style={{
                marginTop: "1.5rem",
                width: "100%",
                padding: "14px 24px",

                borderRadius: 6,
                background: !selectedDate ? "#E8E8E8" : "",
                backgroundImage: selectedDate
                  ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                  : "",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "Inter",
                color: !selectedDate ? "#A2A2A2" : "#FFF",
                border: "1px solid #DDD",
                letterSpacing: "0.5px",
                cursor: "pointer",
              }}
            >
              Next
            </button>
          </>
        )}
        {currentState === "callConfirm" && (
          <Box
            sx={{
              width: "400px",
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "2rem",
            }}
          >
            <Typography
              variant="h5"
              // mb={"2rem"}
              fontWeight={500}
              fontFamily={"Inter"}
              width={"100%"}
            >
              Schedule a call
            </Typography>
            {/* <CustomField
              label="Name"
              value={"Aman upadhyay"}
              fullWidth={true}
              sx={{
                "&.MuiFormControl-root": {
                  // mb: "2rem !important",
                  input: {
                    color: "rgba(0, 0, 0, 0.38)",
                  },
                },
              }}
            /> */}
            <TextField
              disabled
              size="small"
              fullWidth
              label="Name"
              variant="outlined"
              name="name"
              value={userData?.name}
              // onChange={(e) => setName(e.target.value)}
              sx={textFieldStyles}
            />

            {/* <CustomField
              label="Email"
              value={"Aman@gmail.com"}
              fullWidth={true}
              sx={{
                "&.MuiFormControl-root": {
                  // mb: "2rem !important",
                  input: {
                    color: "rgba(0, 0, 0, 0.38)",
                  },
                },
              }}
            /> */}
            <TextField
              disabled
              size="small"
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              value={userData?.email}
              // value={name}
              // onChange={(e) => setName(e.target.value)}

              sx={textFieldStyles}
            />

            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel
                sx={{ backgroundColor: "white", padding: "0 .5rem" }}
                htmlFor="filled-adornment-password"
              >
                Date
              </InputLabel>
              <FilledInput
                // disabled
                style={{
                  // marginBottom: 15,
                  borderRadius: "4px",
                  backgroundColor: "white",
                }}
                value={selectedDate}
                sx={{
                  fontSize: "13px",
                  color: "#1B1D1F",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#c5d2cb",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "rgba(0, 0, 0, 0.60)",
                    fontFamily: "Inter",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "red", // Custom helper text color
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Inter",
                  },
                  "& .MuiInputLabel-root": {
                    fontFamily: "Inter",
                    fontSize: "13px",
                  },
                  input: {
                    fontSize: "13px",
                    padding: "13px 12px",
                  },
                  "&.MuiInputBase-root": {
                    borderColor: "#c5d2cb",
                    border: "1px solid #c5d2cb",
                    paddingRight: "0",
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  },
                }}
                type={"text"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="go to change date"
                      onClick={() => setCurrentState("selectDate")}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1057_21234)">
                          <path
                            d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
                            stroke="#369D9C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.5 2.25V5.25"
                            stroke="#369D9C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.5 2.25V5.25"
                            stroke="#369D9C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.75 8.25H20.25"
                            stroke="#369D9C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 13.125C12.4142 13.125 12.75 12.7892 12.75 12.375C12.75 11.9608 12.4142 11.625 12 11.625C11.5858 11.625 11.25 11.9608 11.25 12.375C11.25 12.7892 11.5858 13.125 12 13.125Z"
                            fill="#369D9C"
                          />
                          <path
                            d="M16.125 13.125C16.5392 13.125 16.875 12.7892 16.875 12.375C16.875 11.9608 16.5392 11.625 16.125 11.625C15.7108 11.625 15.375 11.9608 15.375 12.375C15.375 12.7892 15.7108 13.125 16.125 13.125Z"
                            fill="#369D9C"
                          />
                          <path
                            d="M7.875 16.875C8.28921 16.875 8.625 16.5392 8.625 16.125C8.625 15.7108 8.28921 15.375 7.875 15.375C7.46079 15.375 7.125 15.7108 7.125 16.125C7.125 16.5392 7.46079 16.875 7.875 16.875Z"
                            fill="#369D9C"
                          />
                          <path
                            d="M12 16.875C12.4142 16.875 12.75 16.5392 12.75 16.125C12.75 15.7108 12.4142 15.375 12 15.375C11.5858 15.375 11.25 15.7108 11.25 16.125C11.25 16.5392 11.5858 16.875 12 16.875Z"
                            fill="#369D9C"
                          />
                          <path
                            d="M16.125 16.875C16.5392 16.875 16.875 16.5392 16.875 16.125C16.875 15.7108 16.5392 15.375 16.125 15.375C15.7108 15.375 15.375 15.7108 15.375 16.125C15.375 16.5392 15.7108 16.875 16.125 16.875Z"
                            fill="#369D9C"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1057_21234">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
              <InputLabel
                sx={{ 
                  backgroundColor: "white", 
                  fontFamily: "Inter",
                  fontSize: "13px",
                  "&.Mui-focused": {
                    color: "rgba(0, 0, 0, 0.6)",
                    padding: "0 .5rem",
                  },
                }}
                htmlFor="time-select"
              >
                Select Time
              </InputLabel>
              <Select
                value={getDisplayTime(selectedTime24hr)}
                onChange={handleTimeChange}
                label="Select Time"
                sx={{
                  fontSize: "13px",
                  color: "#1B1D1F",
                  height: "50px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#c5d2cb",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "rgba(0, 0, 0, 0.60)",
                    fontFamily: "Inter",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "red",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Inter",
                    padding: "8px 14px",
                  },
                  "& .MuiInputLabel-root": {
                    fontFamily: "Inter",
                    fontSize: "13px",
                  },
                  "&.MuiInputBase-root": {
                    borderColor: "#c5d2cb",
                    border: "1px solid #c5d2cb",
                    paddingRight: "0",
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "32px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "& .MuiMenuItem-root": {
                        fontSize: "13px",
                        padding: "6px 16px",
                        minHeight: "32px",
                      },
                    },
                  },
                }}
              >
                {timeOptions.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              // disabled={userData?.phone}
              size="small"
              fullWidth
              label="Mobile number"
              variant="outlined"
              name="mobileNumber"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              sx={textFieldStyles}
            />

            

            <GradientButton disabled={!enableConfirm} onClick={handleConfirm}>
              Confirm
            </GradientButton>
          </Box>
        )}
        {currentState === "confirmPopup" && (
          <Box
            sx={{
              width: "auto",
              padding: "1rem", // Reduced from 2rem to 1rem
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                width: "auto",
                height: "100px", // Reduced from 190px to 120px
                margin: "0 auto", // Removed bottom margin
              }}
            >
              <img
                src={sent}
                alt="success"
                style={{ marginTop: "10px", width: "80px" }}
              />{" "}
              {/* Reduced marginTop from 70px to 20px */}
            </div>
            <Typography
              variant="h5"
              mt={".25rem"} // Reduced from .5rem to .25rem
              mb={".5rem"} // Reduced from 1rem to .5rem
              fontFamily={"Inter"}
              width={"100%"}
              align="center"
              fontSize={"20px"}
              sx={{ fontWeight: 600 }}
            >
              Thank You for Booking a Call with Our Expert
            </Typography>
            <Typography
              fontWeight={400}
              fontFamily={"Inter"}
              width={"100%"}
              fontSize={"14px"}
              align="center"
              sx={{ color: "#B1B1B1" }}
              mb={".5rem"} // Reduced from 1rem to .5rem
            >
              Your call with our expert is scheduled. We look forward to
              speaking with you.
            </Typography>
            <button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleGoToDashboard}
              fullwidth
              style={{
                marginTop: ".75rem", // Reduced from 1.5rem to .75rem
                width: "100%",
                padding: "12px 24px", // Reduced padding from 14px to 12px
                borderRadius: 6,
                backgroundImage:
                  "linear-gradient(102deg, #369D9C 0%, #28814D 100%)",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "Inter",
                color: "#FFF",
                border: "1px solid #DDD",
                letterSpacing: "0.5px",
                cursor: "pointer",
              }}
            >
              Go to Dashboard
            </button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default BookCall;
