import React, { useCallback, useEffect, useRef, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useLocation, useParams } from "react-router-dom";
import group from "../../../assets/Group (2).svg";
import emptyuser from "../../../assets/emptyuser.avif";
// import axios from "axios";
import debounce from "lodash.debounce";
import {
  dataPointsToNotMap,
  dataToNotSend,
  // debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
  tooltipMapping,
} from "../../../utils/data-points/cleanObj";
import useFetch from "../../../utils/hooks/useFetch";
import { unitEndpoints } from "../../../api/endpoints/unit.endpoints";
import Loader from "../../Loader";
import { Tooltip } from "@mui/material";
import AssignButton from "../reports/rejected/AssignButton";
import AssignModal from "./assignments/AssignModal";
import { teamEndpoints } from "../../../api/endpoints/team.endpoints";
import constant from "../../../constant";
import { useTaggingStore } from "./assignments/chatStore";

const BaseSector = () => {
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading, refetch } = useFetch(
    reportEndpoints.getReport(params.id)
  );

  const reportData = data?.report?.basicSectorSpecificKPI;



  const { allUsers, assignMembersBySymbol } = useTaggingStore();

  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);

  const location = useLocation();
  const isAiGenerated = location?.state?.generationMethod === "ai" || "";

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    // "Comments",
  ];

  const [dataToMap, setDataToMap] = useState({});
  const [pollInterval, setPollInterval] = useState(null);

  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other };
      setDataToMap(mergedData);
    }
  }, [reportData, loading]);

  // Format number with commas while preserving decimal places
  const formatNumberWithCommas = (value) => {
    if (!value && value !== 0) return "";

    // Convert to string and remove existing commas
    const plainNumber = value.toString().replace(/,/g, "");

    // Split into whole number and decimal parts
    const parts = plainNumber.split(".");

    // Format the whole number part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join back with decimal part if it exists
    return parts.join(".");
  };

  // // Function to fetch latest unread counts
  // const fetchUnreadCounts = async () => {
  //   try {
  //     const response = await axios.get(reportEndpoints.getReport(params.id));
  //     if (response.data && response.data.report) {
  //       const newData = response.data.report.basicSectorSpecificKPI;

  //       // Update only the unread counts while preserving other state
  //       setDataToMap((prevData) => {
  //         const updatedData = { ...prevData };
  //         Object.keys(newData).forEach((symbol) => {
  //           if (updatedData[symbol]) {
  //             updatedData[symbol] = {
  //               ...updatedData[symbol],
  //               unReadMessage: newData[symbol].unReadMessage,
  //             };
  //           }
  //         });
  //         return updatedData;
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching unread counts:", error);
  //   }
  // };

  // // Set up polling when component mounts
  // useEffect(() => {
  //   // Initial fetch
  //   fetchUnreadCounts();

  //   // Set up polling interval (every 5 seconds)
  //   const interval = setInterval(fetchUnreadCounts, 5000);
  //   setPollInterval(interval);

  //   // Cleanup on unmount
  //   return () => {
  //     if (pollInterval) {
  //       clearInterval(pollInterval);
  //     }
  //   };
  // }, []);

  // // Update polling when params.id changes
  // useEffect(() => {
  //   if (pollInterval) {
  //     clearInterval(pollInterval);
  //   }
  //   const newInterval = setInterval(fetchUnreadCounts, 5000);
  //   setPollInterval(newInterval);

  //   return () => {
  //     if (newInterval) {
  //       clearInterval(newInterval);
  //     }
  //   };
  // }, [params.id]);

  // In BaseSector.js



  // const handleInputChange = (symbol, dataKey, value) => {
  //   // Remove commas for calculation
  //   const numericValue = value.replace(/,/g, "");

  //   // Allow empty string and set null
  //   if (numericValue === "") {
  //     setDataToMap((prevState) => {
  //       const updatedData = {
  //         ...prevState,
  //         [symbol]: {
  //           ...prevState[symbol],
  //           [dataKey]: {
  //             ...prevState[symbol][dataKey],
  //             value: null,
  //           },
  //         },
  //       };
  //       debouncedSaveProgress(updatedData);
  //       return updatedData;
  //     });

  //     const basicSectorPayload = {
  //       basicSectorSpecificKPI: {
  //         [symbol]: {
  //           [dataKey]: {
  //             value: null,
  //           },
  //         },
  //       },
  //     };

  //     const environmentalPayload = {
  //       environmentKPI: {
  //         E1: {},
  //         E3: {},
  //       },
  //     };

  //     if (dataKey === "scope1Emissions") {
  //       environmentalPayload.environmentKPI.E1.scope1Emissions = { value: null };
  //     } else if (dataKey === "scope2Emissions") {
  //       environmentalPayload.environmentKPI.E1.scope2Emissions = { value: null };
  //     } else if (dataKey === "totalWaterConsumption") {
  //       environmentalPayload.environmentKPI.E3.waterConsumed = { value: null };
  //     }

  //     const hasEnvironmentalData =
  //       Object.keys(environmentalPayload.environmentKPI.E1).length > 0 ||
  //       Object.keys(environmentalPayload.environmentKPI.E3).length > 0;

  //     Promise.all([
  //       saveProgress(basicSectorPayload),
  //       hasEnvironmentalData ? saveProgress(environmentalPayload) : Promise.resolve(),
  //     ]).catch((error) => {
  //       console.error("Error saving data:", error);
  //     });

  //     return;
  //   }

  //   // Allow input while typing decimals
  //   if (numericValue.endsWith(".")) {
  //     setDataToMap((prevState) => {
  //       const updatedData = {
  //         ...prevState,
  //         [symbol]: {
  //           ...prevState[symbol],
  //           [dataKey]: {
  //             ...prevState[symbol][dataKey],
  //             value: numericValue,
  //           },
  //         },
  //       };
  //       debouncedSaveProgress(updatedData);
  //       return updatedData;
  //     });
  //     return;
  //   }

  //   // Check if it's a valid number with up to 2 decimal places
  //   const isValidNumber = /^(?:\d*\.?\d{0,2}|\.\d{0,2})$/.test(numericValue);
  //   if (!isValidNumber) return;

  //   const unit = dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];
  //   let newValue = numericValue;
  //   const numericValueForValidation = parseFloat(numericValue);

  //   // Handle percentage limits
  //   if (!isNaN(numericValueForValidation) && unit === "%") {
  //     newValue = Math.min(numericValueForValidation, 100).toString();
  //   }

  //   setDataToMap((prevState) => {
  //     const updatedData = {
  //       ...prevState,
  //       [symbol]: {
  //         ...prevState[symbol],
  //         [dataKey]: {
  //           ...prevState[symbol][dataKey],
  //           value: newValue,
  //         },
  //       },
  //     };
  //     debouncedSaveProgress(updatedData);
  //     return updatedData;
  //   });

  //   const basicSectorPayload = {
  //     basicSectorSpecificKPI: {
  //       [symbol]: {
  //         [dataKey]: {
  //           value: newValue,
  //         },
  //       },
  //     },
  //   };

  //   const environmentalPayload = {
  //     environmentKPI: {
  //       E1: {},
  //       E3: {},
  //     },
  //   };

  //   if (dataKey === "scope1Emissions") {
  //     environmentalPayload.environmentKPI.E1.scope1Emissions = { value: newValue };
  //   } else if (dataKey === "scope2Emissions") {
  //     environmentalPayload.environmentKPI.E1.scope2Emissions = { value: newValue };
  //   } else if (dataKey === "totalWaterConsumption") {
  //     environmentalPayload.environmentKPI.E3.waterConsumed = { value: newValue };
  //   }

  //   const hasEnvironmentalData =
  //     Object.keys(environmentalPayload.environmentKPI.E1).length > 0 ||
  //     Object.keys(environmentalPayload.environmentKPI.E3).length > 0;

  //   Promise.all([
  //     saveProgress(basicSectorPayload),
  //     hasEnvironmentalData ? saveProgress(environmentalPayload) : Promise.resolve(),
  //   ])
  //     .then((responses) => {
  //       console.log("Data saved successfully:", responses);
  //     })
  //     .catch((error) => {
  //       console.error("Error saving data:", error);
  //     });
  // };


  // const sendPutRequest = (updatedData) => {
  //   const keysRemoved = removeKeys(updatedData, dataToNotSend);
  //   const filteredData = filterEmptyObjects(keysRemoved);
  //   // Call the API with the updated data
  //   saveProgress({ basicSectorSpecificKPI: filteredData })
  //     .then((response) => {
  //       console.log("PUT request successful:", response.data);
  //     })
  //     .catch((error) => {
  //       console.error("PUT request failed:", error);
  //     });
  // };

  // const debouncedSaveProgress = useCallback(
  //   debounce((updatedData) => {
  //     sendPutRequest(updatedData); // Use updated data here
  //   }, 500),
  //   []
  // );




  ///new function with debounce

  const handleInputChange = (symbol, dataKey, value) => {
    // Remove commas for calculation
    const numericValue = value.replace(/,/g, "");
    let newValue = numericValue;

    // Handle empty input (set value to null)
    if (numericValue === "") newValue = null;

    // Allow input while typing decimals
    if (numericValue === ".") newValue = "0.";

    // Check if it's a valid number with up to 2 decimal places
    const isValidNumber = /^(?:\d*\.?\d{0,2}|\.\d{0,2})$/.test(numericValue);
    if (!isValidNumber) return;

    // Enforce max value for percentage-based inputs
    if (dynamicUnitMapping[dataToMap[symbol]?.[dataKey]?.unit] === "%") {
      const numericValueForValidation = parseFloat(numericValue);
      if (!isNaN(numericValueForValidation) && numericValueForValidation > 100) {
        newValue = "100";
      }
    }

    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: newValue,
          },
        },
      };

      // Create separate payloads
      const basicSectorPayload = { basicSectorSpecificKPI: { [symbol]: { [dataKey]: { value: newValue } } } };

      const environmentalPayload = {
        environmentKPI: { E1: {}, E3: {} },
      };

      // Check for environmental data
      if (dataKey === "scope1Emissions") {
        environmentalPayload.environmentKPI.E1.scope1Emissions = { value: newValue };
      } else if (dataKey === "scope2Emissions") {
        environmentalPayload.environmentKPI.E1.scope2Emissions = { value: newValue };
      } else if (dataKey === "totalWaterConsumption") {
        environmentalPayload.environmentKPI.E3.waterConsumed = { value: newValue };
      }

      // Call separate debounced functions
      debouncedSaveBasicSector.current(basicSectorPayload);
      if (Object.keys(environmentalPayload.environmentKPI.E1).length > 0 ||
        Object.keys(environmentalPayload.environmentKPI.E3).length > 0) {
        debouncedSaveEnvironmentalData.current(environmentalPayload);
      }

      return updatedData;
    });
  };

  // API Call Functions
  const sendBasicSectorRequest = (data) => {
    saveProgress(data)
      .then(() => console.log("Basic Sector data saved:", data))
      .catch((error) => console.error("Error saving Basic Sector:", error));
  };

  const sendEnvironmentalRequest = (data) => {
    saveProgress(data)
      .then(() => console.log("Environmental data saved:", data))
      .catch((error) => console.error("Error saving Environmental Data:", error));
  };

  // Separate debounced functions
  const debouncedSaveBasicSector = useRef(debounce(sendBasicSectorRequest, 500));
  const debouncedSaveEnvironmentalData = useRef(debounce(sendEnvironmentalRequest, 500));

  // Flush pending API calls before unmounting
  useEffect(() => {
    return () => {
      debouncedSaveBasicSector.current.flush();
      debouncedSaveEnvironmentalData.current.flush();
    };
  }, []);




  // const environmentalKpiData = {
  //   scope1Emissions: dataToMap?.scope1Emissions?.value || "",
  //   scope2Emissions: dataToMap?.scope2Emissions?.value || "",
  //   totalWaterConsumption: dataToMap?.totalWaterConsumption?.value || "",
  // };

  // // Update the shared state whenever the data changes
  // React.useEffect(() => {
  //   updateEnvironmentalKpiData(environmentalKpiData);
  // }, [environmentalKpiData]);

  const keyMapping = {
    scope1Emissions: "Scope 1 emissions",
    scope2Emissions: "Scope 2 emissions",
    grossRevenue: "Gross Revenue",
    totalWaterConsumption: "Total Water Consumption",
    totalHazardousWaste: "Total Hazardous Waste Produced",
    totalNonHazardousWaste: "Total Non-Hazardous Waste Produced",
    totalElectricityConsumption: "Total Electricity Consumption (TEC)",
    electricityFromGrid: "Electricity Consumed from Grid (ECG)",
    recPurchased: "REC Purchased for Electricity (REC)",
    electricityFromRenewables: "Electricity from Renewables (ER)",
    Y1: `Revenue allocated for site cleanup in ${data?.report?.year - 3}`,
    Y2: `Revenue allocated for site cleanup in ${data?.report?.year - 2}`,
    Y3: `Revenue allocated for site cleanup in ${data?.report?.year - 1}`,
  };

  const dataPointTooltipMapping = {
    scope1Emissions:
      "Emissions from sources that are owned or controlled by the company, such as fuels burned on site or company-owned vehicles.",
    scope2Emissions:
      "Emissions from the generation of purchased electricity, steam, heating, and cooling needs of the company.",
    grossRevenue:
      "The total income received by the company from its normal business activities, before any expenses are deducted.",
    totalWaterConsumption:
      "The total volume of water used by the company for all operations within a given period.",
    totalHazardousWaste:
      "The total amount of waste generated by the company that poses potential risks to public health or the environment.",
    totalNonHazardousWaste:
      "The total amount of waste generated by the company that is not considered hazardous.",
    totalElectricityConsumption:
      "The total amount of electricity used by the company from all sources during the reporting period.",
    electricityFromGrid:
      "The amount of electricity the company uses that is supplied by the electrical power grid.",
    recPurchased:
      "Renewable Energy Certificates the company has bought to offset its electricity use with renewable sources.",
    electricityFromRenewables:
      "The amount of electricity the company consumes that is generated from renewable energy sources in-house or purchased from contractors.",
    Y1: `Revenue allocated for site cleanup in ${data?.report?.year - 3}`,
    Y2: `Revenue allocated for site cleanup in ${data?.report?.year - 2}`,
    Y3: `Revenue allocated for site cleanup in ${data?.report?.year - 1}`,
  };

  // Create a mapping of unit IDs to unit names from unitsData
  const dynamicUnitMapping = unitsData?.data?.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const isMessages = true;

  const [modalState, setModalState] = useState({
    visible: false,
    top: 0,
    left: 0,
    symbol: null, // Track which symbol triggered the modal
    code: "",
    kpiName: "",
    alreadyTagged: [],
  });

  const handleAssignClick = (event, symbol, alreadyTagged) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const maxModalHeight = 550; // Max height of the modal

    const viewportHeight = window.innerHeight; // Total available viewport height
    const spaceBelow = viewportHeight - buttonRect.bottom; // Space below the button
    const spaceAbove = buttonRect.top; // Space above the button

    // Decide whether to position the modal above or below the button
    let topPosition;
    let modalHeight; // The height of the modal

    if (spaceBelow >= maxModalHeight) {
      // Enough space below the button, place the modal below
      topPosition = buttonRect.bottom;
      modalHeight = maxModalHeight; // Max height because there's enough space below
    } else if (spaceAbove >= maxModalHeight) {
      // Not enough space below, but enough space above, place the modal above
      topPosition = buttonRect.top - maxModalHeight;
      modalHeight = maxModalHeight; // Max height because there's enough space above
    } else if (spaceBelow > spaceAbove) {
      // More space below, use available space below
      topPosition = buttonRect.bottom;
      modalHeight = spaceBelow; // Set height to available space below
    } else {
      // More space above, use available space above
      topPosition = buttonRect.top - spaceAbove;
      modalHeight = spaceAbove; // Set height to available space above
    }

    // Adjust left position, ensuring the modal fits within the viewport width
    const modalWidth = 500; // Assume modal width
    const leftPosition = Math.min(
      buttonRect.right - modalWidth,
      window.innerWidth - modalWidth
    );

    // Set modal state with dynamically calculated top, left, and height
    setModalState({
      visible: true,
      top: topPosition,
      left: leftPosition,
      symbol, // Store the clicked symbol
      height: modalHeight, // Dynamically set the height based on available space
      code: symbol,
      kpiName: "basicSectorSpecificKPI",
      alreadyTagged,
    });
  };

  // Hide the modal
  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
      // top: 0,
      // left: 0,
      // symbol: null,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings?.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${index === 0 || index > 2 ? "centered" : ""
                    }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}

          {/* Table Body */}
          {!loading && !unitsLoading ? (
            <div className="new-kpi-table-body">
              {Object?.keys(dataToMap)?.map((symbol) => {
                if (symbolsToNotMap.includes(symbol)) return null;

                // Check if there are any missing values for the symbol
                const isSymbolMissingValue = Object.keys(
                  dataToMap[symbol]
                ).some(
                  (dataKey) =>
                    !dataPointsToNotMap.includes(dataKey) &&
                    (dataToMap[symbol][dataKey]?.value === undefined ||
                      dataToMap[symbol][dataKey]?.value === "" ||
                      dataToMap[symbol][dataKey]?.value === null ||
                      !dataToMap[symbol][dataKey]?.value)
                );

                if (!dataToMap[symbol]?.assign) {
                  dataToMap[symbol] = {
                    ...dataToMap[symbol],
                    assign: { assignMembers: [] },
                  };
                }

                const assignMembers =
                  dataToMap[symbol]?.assign?.assignMembers || [];

                // Filter `teamMembers` to get only the members with IDs in `assignMembers`
                const assignedUsers =
                  allUsers?.filter((member) =>
                    assignMembers.includes(member.id)
                  ) || [];

                // Determine the background color for the symbol cell
                const symbolColor = !isAiGenerated
                  ? "inherit" // or "none" if you want no color applied, or any other fallback color
                  : isSymbolMissingValue
                    ? "" //#ffeded
                    : "#f6fffa";

                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <Tooltip
                      followCursor={true}
                      title={
                        <span style={{ fontSize: "12px", color: "black" }}>
                          {tooltipMapping[symbol] || "No description available"}
                        </span>
                      }
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "arrow",
                            options: {
                              padding: 5,
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#E6F5F1",
                            color: "black",
                            fontSize: "12px",
                            padding: "8px",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#E6F5F1",
                          },
                        },
                      }}
                    >
                      <div
                        className="new-kpi-table-cell centered"
                        style={{
                          backgroundColor: symbolColor,
                          cursor: "pointer",
                        }}
                      >
                        <div className="new-kpi-table-cell-content">
                          {symbol}
                        </div>
                      </div>
                    </Tooltip>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;
                        // Determine the background color for this specific data point
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <Tooltip
                            followCursor={true}
                            title={
                              <span
                                style={{ fontSize: "12px", color: "black" }}
                              >
                                {dataPointTooltipMapping[dataKey] ||
                                  "No description available"}
                              </span>
                            }
                            placement="top"
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "arrow",
                                  options: {
                                    padding: 5,
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#E6F5F1",
                                  color: "black",
                                  fontSize: "12px",
                                  padding: "8px",
                                },
                              },
                              arrow: {
                                sx: {
                                  color: "#E6F5F1",
                                },
                              },
                            }}
                          >
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content data"
                              style={{ backgroundColor: cellColor }} // Apply background color to individual data points
                            >
                              {keyMapping[dataKey] || dataKey}
                              {dataKey.includes("scope") && (
                                <Tooltip
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#fff", // Tooltip background color
                                        color: "black", // Text color
                                        fontSize: "12px", // Font size
                                        padding: "8px", // Optional: Adjust padding
                                        width: "402px",
                                        maxWidth: "none",
                                        boxShadow: "rgba(12, 12, 13, 0.05)",
                                      },
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#fff", // Arrow color (same as background)
                                      },
                                    },
                                  }}
                                  // placement="right-start"
                                  title={
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                        bgcolor: "white",
                                      }}
                                    >
                                      Calculate your Scope 1, Scope 2, Scope 3
                                      Emission with GHG Tool
                                    </span>
                                  }
                                  placement="right-start"
                                  arrow
                                >
                                  <div style={{ display: "flex" }}>
                                    <img
                                      src={group}
                                      alt=""
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                        margin: "2px",
                                      }}
                                    />
                                    <a
                                      href="https://ghg.ge3s.org/login"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "underline",
                                        color: "black",
                                      }}
                                    >
                                      GHG tool
                                    </a>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing for unit cell
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;

                        // Determine the background color for this specific unit cell
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }}
                          >
                            {/* {dynamicUnitMapping[
                              dataToMap[symbol][dataKey]?.unit
                            ] || "N/A"} */}
                            {(() => {
                              const unit =
                                dynamicUnitMapping[
                                dataToMap[symbol][dataKey]?.unit
                                ] || "N/A";

                              return unit.toLowerCase() === "m3" ? "m³"  : unit;
                            })()}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific value is missing for value cell
                        const isValueMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;

                        // Determine the background color for this specific value cell
                        const cellColor = isAiGenerated
                          ? isValueMissing
                            ? "" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content input-wrapper"
                            style={{ backgroundColor: cellColor }}
                          >
                            {/* <input
                              onWheel={(e) => e.target.blur()}
                              type="number"
                              value={
                                dataToMap[symbol][dataKey]?.value !==
                                  undefined &&
                                dataToMap[symbol][dataKey]?.value !== null
                                  ? dataToMap[symbol][dataKey].value
                                  : ""
                              }
                              className="input-field"
                              placeholder="Enter value"
                              onChange={(e) =>
                                handleInputChange(
                                  symbol,
                                  dataKey,
                                  e.target.value
                                )
                              }
                              onKeyDown={(e) => {
                                if (
                                  e.key === "-" ||
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            /> */}
                            <input
                              onWheel={(e) => e.target.blur()}
                              type="text" // Changed from "number" to "text" to allow comma formatting
                              value={
                                dataToMap[symbol][dataKey]?.value !==
                                  undefined &&
                                  dataToMap[symbol][dataKey]?.value !== null
                                  ? formatNumberWithCommas(
                                    dataToMap[symbol][dataKey].value
                                  )
                                  : ""
                              }
                              className="input-field"
                              placeholder="Enter value"
                              onChange={(e) =>
                                handleInputChange(
                                  symbol,
                                  dataKey,
                                  e.target.value
                                )
                              }
                              onKeyDown={(e) => {
                                // Allow only numbers, backspace, delete, and decimal point
                                if (
                                  !/[\d.,\b]/.test(e.key) && // Allow digits, dot, comma and backspace
                                  e.key !== "Backspace" &&
                                  e.key !== "Delete" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight" &&
                                  e.key !== "Tab" &&
                                  !(e.ctrlKey && (e.key === "c" || e.key === "v")) && // Allow Ctrl+C and Ctrl+V
                                  !(e.metaKey && (e.key === "c" || e.key === "v")) // Allow Cmd+C and Cmd+V
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>

                    {/* Messages */}

                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        {dataToMap[symbol]?.assign?.assignMembers?.length >
                          0 ? (
                          <div className="messages-wrapper">
                            <div className="profile-images-container">
                              {/* Show the first 3 profile images */}
                              {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    img.profileImage
                                      ? `${constant.IMG_URL}/${img.profileImage}`
                                      : emptyuser
                                  }
                                  onError={(e) => {
                                    e.target.src = emptyuser; // If image fails to load, fallback to empty user
                                  }}
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))}

                              {/* Show the count of remaining users if more than 3 */}
                              {assignedUsers.length > 2 && (
                                <div className="remaining-count">
                                  +{assignedUsers.length - 2}
                                </div>
                              )}
                            </div>
                            <div
                              onClick={(event) =>
                                handleAssignClick(event, symbol, assignMembers)
                              }
                              style={{ cursor: "pointer" }}
                              className="notifications-div"
                            >
                              <img
                                src={messages}
                                alt="Messaged"
                                height={24}
                                width={24}
                              />
                              {/* <span className="unread">2 Unread</span> */}
                              {dataToMap[symbol]?.unReadMessage > 0 && (
                                <span className="unread">
                                  {dataToMap[symbol]?.unReadMessage} Unread
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <AssignButton
                            text="Assign"
                            onClick={(event) =>
                              handleAssignClick(event, symbol, assignMembers)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <AssignModal
        closeModal={closeModal}
        modalState={modalState}
      // refetch={refetch}
      />
    </div>
  );
};

export default BaseSector;
