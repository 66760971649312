import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useLocation, useParams } from "react-router-dom";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
  tooltipMapping,
} from "../../../utils/data-points/cleanObj";
import useFetch from "../../../utils/hooks/useFetch";
import { unitEndpoints } from "../../../api/endpoints/unit.endpoints";
import Loader from "../../Loader";
import { Tooltip } from "@mui/material";
import AssignButton from "../reports/rejected/AssignButton";
import AssignModal from "./assignments/AssignModal";
import { useTaggingStore } from "./assignments/chatStore";
import constant from "../../../constant";
import emptyuser from "../../../assets/emptyuser.avif";

const SocialKpi = () => {
  const location = useLocation();
  const isAiGenerated = location?.state?.generationMethod === "ai" || "";
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const reportData = data?.report?.socialKPI;
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);
  const { allUsers } = useTaggingStore();

  const localStorageKey = `socialKpiData`;

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    // "Comments",
  ];

  // Initial state from localStorage or empty object
  // const [dataToMap, setDataToMap] = useState(() => {
  //   const savedData = localStorage.getItem(localStorageKey);
  //   return savedData ? JSON.parse(savedData) : {};
  // });

  const [dataToMap, setDataToMap] = useState({});

  // Sync with reportData only if localStorage is empty
  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other }; // Use only reportData initially if localStorage is empty
      // localStorage.setItem(localStorageKey, JSON.stringify(mergedData)); // Save to localStorage
      setDataToMap(mergedData); // Set state with merged data
    }
  }, [reportData, loading]);

  // Format number with commas while preserving decimal places
  const formatNumberWithCommas = (value) => {
    if (!value && value !== 0) return "";

    // Convert to string and remove existing commas
    const plainNumber = value.toString().replace(/,/g, "");

    // If it ends with a decimal point, preserve it
    if (plainNumber.endsWith(".")) {
      return plainNumber;
    }

    // Split into whole number and decimal parts
    const parts = plainNumber.split(".");

    // Format the whole number part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join back with decimal part if it exists
    return parts.join(".");
  };



  const handleInputChange = (symbol, dataKey, value) => {
    const unit = dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];

    // Handle Yes/No buttons
    if (value === "true" || value === "false") {
      setDataToMap((prevState) => {
        const updatedData = {
          ...prevState,
          [symbol]: {
            ...prevState[symbol],
            [dataKey]: {
              ...prevState[symbol][dataKey],
              value: value === "true",
            },
          },
        };

        const socialSectorPayload = {
          [symbol]: {
            [dataKey]: {
              value: value === "true",
            },
          },
        };

        debouncedSaveProgress(socialSectorPayload);
        return updatedData;
      });
      return;
    }

    // Handle numeric inputs
    const numericValue = value.replace(/,/g, "");

    // Handle empty input (set to null)
    if (numericValue.trim() === "") {
      setDataToMap((prevState) => ({
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: null, // Change to null instead of empty string
          },
        },
      }));

      const socialSectorPayload = {
        [symbol]: {
          [dataKey]: {
            value: null,
          },
        },
      };

      // Ensure API gets null
      debouncedSaveProgress(socialSectorPayload);

      return;
    }

    // Handle decimal point input
    if (numericValue === ".") {
      setDataToMap((prevState) => ({
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: "0.",
          },
        },
      }));
      return;
    }

    // Check if it's a valid decimal number
    const isValidNumber = /^\d*\.?\d*$/.test(numericValue);
    if (!isValidNumber) return;

    // Preserve decimal formatting
    let newValue = numericValue;

    // Handle percentage limits
    if (unit === "%") {
      const numericValueForValidation = parseFloat(numericValue);
      if (!isNaN(numericValueForValidation) && numericValueForValidation > 100) {
        newValue = "100";
      }
    }

    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: newValue,
          },
        },
      };

      const socialSectorPayload = {
        [symbol]: {
          [dataKey]: {
            value: newValue,
          },
        },
      };

      debouncedSaveProgress(socialSectorPayload);
      return updatedData;
    });
  };



  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ socialKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    totalMen: "Total Number of men in the workforce",
    totalWomen: "Total Number of women in the workforce",
    totalNonBinary:
      "Total Number of people in the workforce who identify as non-binary",
    foreignEmployees: "Number of foreign nationals in work-force",
    directEmployees: "Number of Direct employees",
    indirectEmployees:
      "Number of Indirect employees (contractors, outsourced etc)",
    averageTenure: "Average tenure of employees in the organisation",
    trainingHours: "Average number of Hours of training provided to employees",
    nonDiscriminatoryPolicy:
      "Do you have a Non-discriminatory policy in place?",
    trir: "TRIR (total recordable incident rate)",
    campusHires: "Total number of employees hired through campus placements",
  };

  const dataPointTooltipMapping = {
    totalMen:
      "The total number of biologically male employees working for the company.",
    totalWomen:
      "The total number of biologically female employees working for the company.",
    totalNonBinary:
      "The total number of employees who are neither biologically male nor biologically female.",
    foreignEmployees:
      "The number of employees in the company who are not nationals of the country where they work.",
    directEmployees:
      " Employees who are directly employed by the company (not through an agency or contractor).",
    indirectEmployees:
      "Employees who are hired through third-party agencies or contractors.",
    averageTenure:
      "The average duration of time employees have worked for the company.",
    trainingHours: "Total training hours provided to employees per year.",
    nonDiscriminatoryPolicy:
      "Indicates whether the company has an official policy that prohibits discrimination in the workplace.",
    trir: "The number of workplace incidents per 200,000 hours worked.",
    campusHires:
      "The number of employees the company has hired directly from educational institutions.",
  };

  const unitMapping = {
    "66d7562107b0aeb0cb66609a": "tCO2e",
    "66d7562207b0aeb0cb66609d": "million USD",
    "66d7562207b0aeb0cb6660a4": "m3",
    "66d7562207b0aeb0cb6660a9": "tonnes",
    "66d7562207b0aeb0cb6660ad": "MWh",
    "66d7562207b0aeb0cb6660af": "MWh eq",
    "66d7562207b0aeb0cb6660ab": "%",
    "66d7562207b0aeb0cb6660b3": "dB",
    "66d7562207b0aeb0cb6660b1": "ppm",
    "66d7562307b0aeb0cb6660b7": "Number",
    "66d7562307b0aeb0cb6660b9": "years",
    "66d7562307b0aeb0cb6660bb": "hours/year/employee",
    "66d7562307b0aeb0cb6660bd": "Yes/No",
    "66d7562307b0aeb0cb6660bf": "Incidients per 200,000 hours worked",
  };

  // Create a mapping of unit IDs to unit names from unitsData
  const dynamicUnitMapping = unitsData?.data.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const isMessages = true;

  const [modalState, setModalState] = useState({
    visible: false,
    top: 0,
    left: 0,
    symbol: null, // Track which symbol triggered the modal
    code: "",
    kpiName: "",
    alreadyTagged: [],
  });

  const handleAssignClick = (event, symbol, alreadyTagged) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const maxModalHeight = 550; // Max height of the modal

    const viewportHeight = window.innerHeight; // Total available viewport height
    const spaceBelow = viewportHeight - buttonRect.bottom; // Space below the button
    const spaceAbove = buttonRect.top; // Space above the button

    // Decide whether to position the modal above or below the button
    let topPosition;
    let modalHeight; // The height of the modal

    if (spaceBelow >= maxModalHeight) {
      // Enough space below the button, place the modal below
      topPosition = buttonRect.bottom;
      modalHeight = maxModalHeight; // Max height because there's enough space below
    } else if (spaceAbove >= maxModalHeight) {
      // Not enough space below, but enough space above, place the modal above
      topPosition = buttonRect.top - maxModalHeight;
      modalHeight = maxModalHeight; // Max height because there's enough space above
    } else if (spaceBelow > spaceAbove) {
      // More space below, use available space below
      topPosition = buttonRect.bottom;
      modalHeight = spaceBelow; // Set height to available space below
    } else {
      // More space above, use available space above
      topPosition = buttonRect.top - spaceAbove;
      modalHeight = spaceAbove; // Set height to available space above
    }

    // Adjust left position, ensuring the modal fits within the viewport width
    const modalWidth = 500; // Assume modal width
    const leftPosition = Math.min(
      buttonRect.right - modalWidth,
      window.innerWidth - modalWidth
    );

    // Set modal state with dynamically calculated top, left, and height
    setModalState({
      visible: true,
      top: topPosition,
      left: leftPosition,
      symbol, // Store the clicked symbol
      height: modalHeight, // Dynamically set the height based on available space
      code: symbol,
      kpiName: "socialKPI",
      alreadyTagged,
    });
  };

  // Hide the modal
  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings?.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${index === 0 || index > 2 ? "centered" : ""
                    }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}
          {!loading && !unitsLoading ? (
            <div className="new-kpi-table-body">
              {Object?.keys(dataToMap)?.map((symbol) => {
                if (symbolsToNotMap.includes(symbol)) return null;

                // Check if any value is missing for the symbol
                const isSymbolMissingValue = Object.keys(
                  dataToMap[symbol]
                ).some(
                  (dataKey) =>
                    !dataPointsToNotMap.includes(dataKey) &&
                    (dataToMap[symbol][dataKey]?.value === undefined ||
                      dataToMap[symbol][dataKey]?.value === "" ||
                      dataToMap[symbol][dataKey]?.value === null)
                );

                // Determine background color for the symbol cell
                const symbolColor = !isAiGenerated
                  ? "inherit" // or "none" if you want no color applied, or any other fallback color
                  : isSymbolMissingValue
                    ? "" //#ffeded
                    : "#f6fffa";

                if (!dataToMap[symbol]?.assign) {
                  dataToMap[symbol] = {
                    ...dataToMap[symbol],
                    assign: { assignMembers: [] },
                  };
                }

                const assignMembers =
                  dataToMap[symbol]?.assign?.assignMembers || [];

                // Filter `teamMembers` to get only the members with IDs in `assignMembers`
                const assignedUsers =
                  allUsers?.filter((member) =>
                    assignMembers.includes(member.id)
                  ) || [];

                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <Tooltip
                      followCursor={true}
                      title={
                        <span style={{ fontSize: "12px", color: "black" }}>
                          {tooltipMapping[symbol] || "No description available"}
                        </span>
                      }
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "arrow",
                            options: {
                              padding: 5, // Offset the arrow a bit
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#E6F5F1", // Tooltip background color
                            color: "black", // Text color
                            fontSize: "12px", // Font size
                            padding: "8px", // Optional: Adjust padding
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#E6F5F1", // Arrow color (same as background)
                          },
                        },
                      }}
                    >
                      <div
                        className="new-kpi-table-cell centered"
                        style={{
                          backgroundColor: symbolColor,
                          cursor: "pointer",
                        }}
                      >
                        <div className="new-kpi-table-cell-content">
                          {symbol}
                        </div>
                      </div>
                    </Tooltip>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific data point
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        return (
                          <Tooltip
                            followCursor={true}
                            title={
                              <span
                                style={{ fontSize: "12px", color: "black" }}
                              >
                                {dataPointTooltipMapping[dataKey] ||
                                  "No description available"}
                              </span>
                            }
                            placement="top"
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "arrow",
                                  options: {
                                    padding: 5,
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#E6F5F1",
                                  color: "black",
                                  fontSize: "12px",
                                  padding: "8px",
                                },
                              },
                              arrow: {
                                sx: {
                                  color: "#E6F5F1",
                                },
                              },
                            }}
                          >
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content data"
                              style={{ backgroundColor: cellColor }} // Apply background color to individual data points
                            >
                              {keyMapping[dataKey] || dataKey}
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing for unit cell
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific unit cell
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        const unit =
                          dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];
                        const showUnit = unit === "0/1" ? "Yes/No" : unit;

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }}
                          >
                            {showUnit || "N/A"}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific value is missing for value cell
                        const isValueMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific value cell
                        const cellColor = isAiGenerated
                          ? isValueMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        const unit =
                          unitMapping[dataToMap[symbol][dataKey].unit];
                        const value = dataToMap[symbol][dataKey].value;

                        if (unit === "Yes/No") {
                          return (
                            <div
                              className="new-kpi-table-cell-content input-wrapper"
                              key={idx}
                              style={{ backgroundColor: cellColor }} // Apply background color to Yes/No buttons
                            >
                              <button
                                className={`yes-no-btn yes-btn ${value === true ? "yes-btn-selected" : ""
                                  }`}
                                onClick={() =>
                                  handleInputChange(symbol, dataKey, "true")
                                }
                              >
                                Yes
                              </button>
                              <button
                                className={`yes-no-btn no-btn ${value === false ? "no-btn-selected" : ""
                                  }`}
                                onClick={() =>
                                  handleInputChange(symbol, dataKey, "false")
                                }
                              >
                                No
                              </button>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content input-wrapper"
                              style={{ backgroundColor: cellColor }} // Apply background color to individual input cells
                            >
                              {/* <input
                                onWheel={(e) => e.target.blur()}
                                type="number"
                                value={
                                  dataToMap[symbol][dataKey]?.value !==
                                    undefined &&
                                  dataToMap[symbol][dataKey]?.value !== null
                                    ? dataToMap[symbol][dataKey].value
                                    : ""
                                }
                                className="input-field"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleInputChange(
                                    symbol,
                                    dataKey,
                                    e.target.value
                                  )
                                }
                              /> */}
                              <input
                                onWheel={(e) => e.target.blur()}
                                type="text"
                                value={
                                  dataToMap[symbol][dataKey]?.value !==
                                    undefined &&
                                    dataToMap[symbol][dataKey]?.value !== null
                                    ? formatNumberWithCommas(
                                      dataToMap[symbol][dataKey].value
                                    )
                                    : ""
                                }
                                className="input-field"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleInputChange(
                                    symbol,
                                    dataKey,
                                    e.target.value
                                  )
                                }
                                onKeyDown={(e) => {
                                  // Allow decimal points and numbers
                                  const isNumber = /[0-9]/.test(e.key);
                                  const isDecimalPoint = e.key === ".";
                                  const hasDecimalPoint =
                                    e.target.value.includes(".");
                                  const isNavigationKey = [
                                    "Backspace",
                                    "Delete",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Tab",
                                  ].includes(e.key);

                                  // Allow the key if it's a number, navigation key, or first decimal point
                                  if (
                                    !isNumber &&
                                    !isNavigationKey &&
                                    !(isDecimalPoint && !hasDecimalPoint) &&
                                    !(e.ctrlKey && (e.key === "c" || e.key === "v")) && // Allow Ctrl+C and Ctrl+V
                                    !(e.metaKey && (e.key === "c" || e.key === "v")) // Allow Cmd+C and Cmd+V
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>

                    {/* Messages */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        {dataToMap[symbol]?.assign?.assignMembers?.length >
                          0 ? (
                          <div className="messages-wrapper">
                            <div className="profile-images-container">
                              {/* Show the first 3 profile images */}
                              {/* {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    `${constant.IMG_URL}/${img.profileImage}` ??
                                    dummyUser
                                  }
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))} */}
                              {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    img.profileImage
                                      ? `${constant.IMG_URL}/${img.profileImage}`
                                      : emptyuser
                                  }
                                  onError={(e) => {
                                    e.target.src = emptyuser; // If image fails to load, fallback to empty user
                                  }}
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))}

                              {/* Show the count of remaining users if more than 3 */}
                              {assignedUsers.length > 2 && (
                                <div className="remaining-count">
                                  +{assignedUsers.length - 2}
                                </div>
                              )}
                            </div>
                            <div
                              onClick={(event) =>
                                handleAssignClick(event, symbol, assignMembers)
                              }
                              style={{ cursor: "pointer" }}
                              className="notifications-div"
                            >
                              <img
                                src={messages}
                                alt="Messaged"
                                height={24}
                                width={24}
                              />
                              {/* <span className="unread">2 Unread</span> */}
                              {dataToMap[symbol]?.unReadMessage > 0 && (
                                <span className="unread">
                                  {dataToMap[symbol]?.unReadMessage} Unread
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <AssignButton
                            text="Assign"
                            onClick={(event) =>
                              handleAssignClick(event, symbol, assignMembers)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            // <p>LOADING...</p>
            <Loader />
          )}
        </div>
      </div>

      <AssignModal closeModal={closeModal} modalState={modalState} />
    </div>
  );
};

export default SocialKpi;
